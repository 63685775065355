var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "lesson-layouts", attrs: { color: "#ffffff" } },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _c(
                "v-slide-group",
                {
                  staticClass: "py-2",
                  attrs: { "center-active": "" },
                  model: {
                    value: _vm.localSlideGroupModel,
                    callback: function($$v) {
                      _vm.localSlideGroupModel = $$v
                    },
                    expression: "localSlideGroupModel"
                  }
                },
                [
                  _vm.$currentUser.userMode === "A"
                    ? _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                transition: "scroll-y-transition",
                                "offset-y": "",
                                "max-height": "500",
                                "min-width": "90"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "ml-2",
                                                attrs: {
                                                  color: "primary",
                                                  outlined: "",
                                                  rounded: "",
                                                  small: ""
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.localAdminSelectedTabName
                                                ) +
                                                " "
                                            ),
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "ml-2",
                                                attrs: { small: "" }
                                              },
                                              [_vm._v("fal fa-chevron-down")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3317983016
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: {
                                        color: "primary",
                                        mandatory: "",
                                        "aria-label": _vm.$t("layoutTypeLabel")
                                      },
                                      model: {
                                        value: _vm.localAdminSelectedTab,
                                        callback: function($$v) {
                                          _vm.localAdminSelectedTab = $$v
                                        },
                                        expression: "localAdminSelectedTab"
                                      }
                                    },
                                    [
                                      _c("v-list-item", [
                                        _vm._v(_vm._s(_vm.$t("schoolLabel")))
                                      ]),
                                      _c("v-list-item", [
                                        _vm._v(_vm._s(_vm.$t("districtLabel")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "rounded-0", attrs: { elevation: "0" } },
                [
                  _c("v-data-table", {
                    key: "lessonLayouts-" + _vm.localRefreshKey,
                    ref: "table",
                    attrs: {
                      headers: _vm.localHeaders,
                      items: _vm.filteredLocalLessonLayouts,
                      "item-key": "lessonLayoutId",
                      "selectable-key": "selectable",
                      height: _vm.tableHeight,
                      search: _vm.localSearch,
                      loading: _vm.localListLoading,
                      "multi-sort": false,
                      "fixed-header": "",
                      "show-select":
                        _vm.localIsEditingLessonLayoutList &&
                        (_vm.$currentUser.isTeacher ||
                          (_vm.$currentUser.isAdmin &&
                            ((_vm.userType !== "D" &&
                              _vm.localAdminSelectedTab === 0) ||
                              _vm.userType === "D"))),
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      "loading-text": _vm.$t("listLoadingMsg"),
                      "mobile-breakpoint": _vm.mobileBreakpoint
                    },
                    on: { "click:row": _vm.viewEditLessonLayout },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container"
                              },
                              _vm._l(items, function(item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.viewEditLessonLayout(item)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.viewEditLessonLayout(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm.localIsEditingLessonLayoutList &&
                                    (_vm.$currentUser.isTeacher ||
                                      (_vm.$currentUser.isAdmin &&
                                        ((_vm.userType !== "D" &&
                                          _vm.localAdminSelectedTab === 0) ||
                                          _vm.userType === "D")))
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              }
                                            }
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px"
                                              },
                                              attrs: {
                                                disabled: !item.selectable,
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": item.name
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.$currentUser.isTeacher ||
                                    _vm.isEditable(item)
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.localHeaders[0].text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "pb-btn",
                                              {
                                                staticClass: "mx-3",
                                                class: _vm.mobileCellClass,
                                                attrs: {
                                                  label: _vm.$currentUser
                                                    .isTeacher
                                                    ? _vm.$t(
                                                        "markAsCurrentLabel"
                                                      )
                                                    : _vm.$t(
                                                        "markAsDefaultLabel"
                                                      ),
                                                  small: "",
                                                  fab: "",
                                                  elevation: "0"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.toggleCurrentOrDefault(
                                                      item
                                                    )
                                                  },
                                                  keyup: function($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                  }
                                                }
                                              },
                                              [
                                                (_vm.$currentUser.isTeacher &&
                                                  item.current === "Y") ||
                                                (_vm.$currentUser.isAdmin &&
                                                  item.defaultLayout === "Y")
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-solid fa-star",
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-regular fa-star"
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.localHeaders[0].text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                class: _vm.mobileCellClass,
                                                staticStyle: {
                                                  padding: "0px 18px 0px 19px"
                                                }
                                              },
                                              [
                                                (_vm.$currentUser.isTeacher &&
                                                  item.current == "Y") ||
                                                (_vm.$currentUser.isAdmin &&
                                                  item.defaultLayout === "Y")
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-solid fa-star",
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-regular fa-star"
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            )
                                          ]
                                        ),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.localHeaders[1].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.level))]
                                      )
                                    ]),
                                    item.lessonLayoutId === -1 ||
                                    !_vm.isEditable(item)
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.localHeaders[2].text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.name))
                                                ])
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.localHeaders[2].text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            item.isEditingName
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileCellClass
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        "append-icon":
                                                          "fa fa-check",
                                                        value: item.name,
                                                        disabled:
                                                          _vm.localListLoading,
                                                        autofocus: "",
                                                        dense: "",
                                                        "hide-details": ""
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !$event.type.indexOf(
                                                              "key"
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter",
                                                              13,
                                                              $event.key,
                                                              "Enter"
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          return _vm.saveQuickEditName(
                                                            item
                                                          )
                                                        },
                                                        "click:append": function(
                                                          $event
                                                        ) {
                                                          return _vm.saveQuickEditName(
                                                            item
                                                          )
                                                        },
                                                        blur: function($event) {
                                                          return _vm.saveQuickEditName(
                                                            item
                                                          )
                                                        },
                                                        input: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateQuickEditName(
                                                            item,
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileCellClass,
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        return _vm.quickEditName(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(_vm._s(item.name))
                                                    ]),
                                                    _vm.isEditable(item)
                                                      ? _c(
                                                          "span",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "pl-1 hover-edit-icon",
                                                                attrs: {
                                                                  "x-small": ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "fal fa-pencil"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                          ]
                                        ),
                                    _c(
                                      "td",
                                      {
                                        staticClass: "text-right",
                                        class: _vm.mobileRowClass
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.localHeaders[3].text
                                                  )
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            class: _vm.mobileCellClass,
                                            attrs: {
                                              align: "center",
                                              justify: "center"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "mx-3 hover-button-primary",
                                                attrs: {
                                                  color:
                                                    _vm.contentBreakpoint ===
                                                    "xs"
                                                      ? "primary"
                                                      : "transparent",
                                                  text: "",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.viewEditLessonLayout(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm.isEditable(item)
                                                  ? _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("editLabel")
                                                        )
                                                      )
                                                    ])
                                                  : _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "customizeLabel"
                                                          )
                                                        )
                                                      )
                                                    ])
                                              ]
                                            ),
                                            _vm.isEditable(item) &&
                                            item.lessonLayoutId != -1
                                              ? _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "mx-3 hover-button-error",
                                                    attrs: {
                                                      color:
                                                        _vm.contentBreakpoint ===
                                                        "xs"
                                                          ? "error"
                                                          : "transparent",
                                                      text: "",
                                                      small: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.removeLessonLayout(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("deleteLabel")
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.localSelectedRows,
                      callback: function($$v) {
                        _vm.localSelectedRows = $$v
                      },
                      expression: "localSelectedRows"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "lessonLayoutsConfirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }