

























































































































































































import CommonUtils from '@/utils/common-utils';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from './../../components/core/Confirm.vue';
import ld from 'lodash';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { tableWidths } from '@/constants/index';
import TableResizeMixin from '@/mixins/table-resize-mixin';

const settings = namespace('settings');
const lessonlayouts = namespace('lessonlayouts');

@Component({
  mixins: [PageLifeCycleMixin, TableResizeMixin]
})
export default class LessonLayouts extends Vue {
  tableHeight!: string;
  localRefreshKey = CommonUtils.generateUUID();
  localLessonLayouts: any = [];
  localIsEditingLessonLayoutList = false;
  localSearch = '';
  localSelectedRows = [];
  localAdminSelectedTab = 0;
  localSlideGroupModel = null;

  $refs!: {
    lessonLayoutsConfirm: Confirm
  }

  @lessonlayouts.State
  selectedLayout!: any;

  @settings.Getter('getUserType')
  userType!: any;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getCurrentYear')
  currentYear!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @lessonlayouts.Mutation
  resetLayout!: any;

  @lessonlayouts.Mutation
  setHaveLayouts!: (value: boolean) => void;

  @lessonlayouts.Getter
  getLayouts!: any;

  @lessonlayouts.Getter
  getListLoading!: any;

  @lessonlayouts.Action
  loadLayouts!: () => Promise<any>;

  @lessonlayouts.Action
  loadLayout!: (params?: any) => Promise<any>;

  @lessonlayouts.Action
  addOrUpdateLayout!: (params?: any) => Promise<any>;

  @lessonlayouts.Action
  saveAsCurrenLayout!: (params?: any) => Promise<any>;

  @lessonlayouts.Action
  deleteLayouts!: (params?: any) => Promise<any>;

  get filteredLocalLessonLayouts(): any {
    if (this.$currentUser.isAdmin && this.localAdminSelectedTab === 0) {
      return this.localLessonLayouts.filter((ll: any) => ll.schoolId > 0);
    } else if (this.$currentUser.isAdmin && this.localAdminSelectedTab === 1) {
      return this.localLessonLayouts.filter((ll: any) => ll.districtId > 0);
    } else {
      return this.localLessonLayouts;
    }
  }

  get localHeaders() {
    return [
      {
        text: this.$currentUser.isTeacher ? this.$t('currentLabel') : this.$t('defaultLabel'),
        value: 'default',
        align: 'center',
        sortable: false,
        width: tableWidths.icon
      },
      {
        text: this.$t('typeLabel'),
        value: 'level',
        width: tableWidths.shortText
      },
      {
        text: this.$t('nameLabel'),
        value: 'name'
      },
      {
        width: tableWidths.action2,
        sortable: false,
        value: 'action'
      }
    ]
  }

  get lessonLayouts(): any {
    return this.getLayouts;
  }

  get filteredLocalHeaders() {
    return this.localHeaders.filter(key => key.text !== this.$t('defaultLabel'));
  }

  get hasSelectedRows() {
    return CommonUtils.isNotEmpty(this.localSelectedRows);
  }

  get localListLoading() {
    return this.getListLoading;
  }

  set localListLoading(value: boolean) {
    this.$store.commit('lessonlayouts/setListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get isActionable() {
    return this.$currentUser.isTeacher || (this.$currentUser.isAdmin && ((this.userType !== 'D' && this.localAdminSelectedTab === 0) || this.userType === 'D'))
  }

  get localAdminSelectedTabName() {
    return (this.localAdminSelectedTab ? this.$t('districtLabel') : this.$t('schoolLabel'));
  }

  isEditable(layout: any) {
    return (this.$currentUser.isTeacher && layout.teacherId > 0) || (this.$currentUser.isAdmin && this.userType !== 'D' && layout.schoolId > 0) || (this.$currentUser.isAdmin && this.userType === 'D' && (layout.schoolId > 0 || layout.districtId > 0));
  }

  addLessonLayout() {
    this.resetLayout();
    this.$eventBus.$emit('openSubPage', {
      type: 'lessonLayout',
      width: 650
    });
    CommonUtils.hideLoading();
  }

  viewEditLessonLayout(item: any) {
    CommonUtils.showLoading();
    this.resetLayout();
    this.loadLayout({ lessonLayoutId: item.lessonLayoutId }).then(() => {
      this.$eventBus.$emit('openSubPage', {
        type: 'lessonLayout',
        width: 650
      });
    }).finally(CommonUtils.hideLoading);
  }

  removeLessonLayout(layout: any) {
    const that = this;
    this.$refs.lessonLayoutsConfirm.confirm({
      title: this.$t('lessonLayoutLabel'),
      text: this.$currentUser.isTeacher ? this.$t('deleteLessonLayoutFromTeacherConfirmMsg') : this.$t('deleteLessonLayoutFromSchoolOrDistrictConfirmMsg', { org: layout.districtId > 0 ? this.$t('districtLabel') : this.$t('schoolLabel') }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        this.localListLoading = true;
        that.deleteLayouts({ lessonLayoutIds: [layout.lessonLayoutId] }).then(() => {
          this.$eventBus.$emit('deleteLessonLayout', [layout.lessonLayoutId]);
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg57') as string);
          }
          this.localListLoading = false;
          that.initialize(false);
        });
      }
    });
  }

  removeLessonLayouts() {
    if (this.hasSelectedRows) {
      const that = this;
      this.$refs.lessonLayoutsConfirm.confirm({
        title: this.$t('lessonLayoutLabel'),
        text: this.$currentUser.isTeacher ? this.$t('deleteLessonLayoutsFromTeacherConfirmMsg') : this.$t('deleteLessonLayoutsFromSchoolOrDistrictConfirmMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result === 1) {
          this.localListLoading = true;
          const lessonLayoutIds = this.localSelectedRows.map((ll:any) => { return ll.lessonLayoutId; });
          that.deleteLayouts({ lessonLayoutIds: lessonLayoutIds }).then(() => {
            this.$eventBus.$emit('deleteLessonLayout', lessonLayoutIds);
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg57') as string);
            }
            this.localListLoading = false;
            that.initialize(false);
          });
        }
      });
    }
  }

  toggleCurrentOrDefault(item: any) {
    const that = this;
    this.localListLoading = true;
    if (this.$currentUser.isTeacher) {
      this.saveAsCurrenLayout({ lessonLayoutId: item.lessonLayoutId }).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg60') as string);
        }
        return that.reloadSettings();
      }).finally(() => {
        that.initialize(false);
        that.localListLoading = false;
      });
    } else {
      if (this.isEditable(item)) {
        const lessonLayout = ld.cloneDeep(item);
        lessonLayout.defaultLayout = 'Y';
        this.updateLessonLayout(lessonLayout).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg60b') as string);
          }
          return that.reloadSettings();
        }).finally(() => {
          that.initialize(false);
          that.localListLoading = false;
        });
      }
    }
  }

  quickEditName(item: any) {
    if (this.isEditable(item)) {
      const layouts = ld.cloneDeep(this.localLessonLayouts);
      layouts.forEach((l: any) => {
        l.isEditingName = false;
      });
      const layout = layouts.find((l: any) => l.lessonLayoutId === item.lessonLayoutId);
      layout.isEditingName = true;
      this.localLessonLayouts = layouts;
    }
  }

  updateQuickEditName(item: any, value: string) {
    const layouts = ld.cloneDeep(this.localLessonLayouts);
    const layout = layouts.find((l: any) => l.lessonLayoutId === item.lessonLayoutId);
    layout.name = value;
    this.localLessonLayouts = layouts;
  }

  saveQuickEditName(item: any) {
    const that = this;
    if (this.isEditable(item)) {
      this.localListLoading = true;
      const layouts = ld.cloneDeep(this.localLessonLayouts);
      const layout = layouts.find((l: any) => l.lessonLayoutId === item.lessonLayoutId);
      this.updateLessonLayout(layout).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg58') as string);
        }
        that.initialize(false);
      }).finally(() => {
        that.disableEditable();
        that.localListLoading = false;
      });
    }
  }

  disableEditable() {
    this.resetLayout();
    const layouts = ld.cloneDeep(this.localLessonLayouts);
    layouts.forEach((l: any) => {
      l.isEditingName = false;
    });
    this.localLessonLayouts = layouts;
  }

  toggleEditMode() {
    this.localIsEditingLessonLayoutList = !this.localIsEditingLessonLayoutList;
    if (this.localIsEditingLessonLayoutList === false) {
      this.localSelectedRows = [];
    }
  }

  onUserModeChanged() {
    this.initialize(false);
  }

  created() {
    this.initialize(true);
    this.$nextTick(() => {
      this.$eventBus.$on('refreshLessonLayouts', () => {
        this.initialize(false);
      });
      this.$eventBus.$on('userModeChanged', this.onUserModeChanged);
      this.$eventBus.$emit('openDefaultSubPage')
    });
  }

  destroyed() {
    this.$eventBus.$off('refreshLessonLayouts');
    this.$eventBus.$off('userModeChanged', this.onUserModeChanged);
  }

  private getLessonLayoutLevel(lessonLayoutData: any) {
    if (lessonLayoutData.districtId > 0) {
      return this.$t('districtLabel');
    } else if (lessonLayoutData.schoolId > 0) {
      return this.$t('schoolLabel');
    } else {
      return this.$t('teacherLabel');
    }
  }

  private updateLessonLayout(item: any) {
    if (item) {
      const lessonLayout: any = {
        action: item.districtId > 0 ? 'D' : (item.schoolId > 0 ? 'S' : 'T'),
        name: item.name,
        lessonLayoutId: item.lessonLayoutId !== 0 ? item.lessonLayoutId : null,
        defaultLayout: item.defaultLayout,
        tab1Label: item.tab1Label,
        tab2Label: item.tab2Label,
        tab3Label: item.tab3Label,
        tab4Label: item.tab4Label,
        tab5Label: item.tab5Label,
        tab6Label: item.tab6Label,
        tab7Label: item.tab7Label,
        tab8Label: item.tab8Label,
        tab9Label: item.tab9Label,
        strategiesLabel: item.strategiesLabel,
        tab1DisplayOrder: item.tab1DisplayOrder,
        tab2DisplayOrder: item.tab2DisplayOrder,
        tab3DisplayOrder: item.tab3DisplayOrder,
        tab4DisplayOrder: item.tab4DisplayOrder,
        tab5DisplayOrder: item.tab5DisplayOrder,
        tab6DisplayOrder: item.tab6DisplayOrder,
        tab7DisplayOrder: item.tab7DisplayOrder,
        tab8DisplayOrder: item.tab8DisplayOrder,
        tab9DisplayOrder: item.tab9DisplayOrder,
        strategiesDisplayOrder: item.strategiesDisplayOrder,
        tab1Enabled: item.tab1Enabled,
        tab2Enabled: item.tab2Enabled,
        tab3Enabled: item.tab3Enabled,
        tab4Enabled: item.tab4Enabled,
        tab5Enabled: item.tab5Enabled,
        tab6Enabled: item.tab6Enabled,
        tab7Enabled: item.tab7Enabled,
        tab8Enabled: item.tab8Enabled,
        tab9Enabled: item.tab9Enabled,
        strategiesEnabled: item.strategiesEnabled
      };
      if (item.sections && item.sections.length > 0) {
        item.sections.forEach((section: any, index: number) => {
          lessonLayout[section.displaySettingsIdKey] = section.displaySettingsId;
          lessonLayout[section.parentIdKey] = section.parentId;
          lessonLayout[section.labelKey] = section.label;
          lessonLayout[section.orderKey] = index + 1;
          lessonLayout[section.enabledKey] = section.enabled;
          lessonLayout[section.titleColorKey] = section.titleColor;
          lessonLayout[section.titleFontKey] = section.titleFont;
          lessonLayout[section.showTitleKey] = !CommonUtils.isTrue(section.showTitle);
          lessonLayout[section.titleBoldKey] = section.titleBold;
          lessonLayout[section.titleFillColorKey] = section.titleFillColor;
          lessonLayout[section.titleItalicKey] = section.titleItalic;
          lessonLayout[section.titleUnderlineKey] = section.titleUnderline;
          lessonLayout[section.titleFontSizeKey] = section.titleFontSize;
          lessonLayout[section.bodyBoldKey] = section.bodyBold;
          lessonLayout[section.bodyColorKey] = section.bodyColor;
          lessonLayout[section.bodyFillColorKey] = section.bodyFillColor;
          lessonLayout[section.bodyFontKey] = section.bodyFont;
          lessonLayout[section.bodyItalicKey] = section.bodyItalic;
          lessonLayout[section.bodyFontSizeKey] = section.bodyFontSize;
          lessonLayout[section.bodyUnderlineKey] = section.bodyUnderline;
        });
      }
      return this.addOrUpdateLayout(lessonLayout);
    }
    return Promise.resolve(false);
  }

  private initialize(firstLoad: boolean) {
    firstLoad ? CommonUtils.showLoading() : this.localListLoading = true;
    this.resetLayout();
    this.setHaveLayouts(false);
    this.loadLayouts().then(() => {
      this.localLessonLayouts = this.$currentUser.isTeacher ? ld.cloneDeep(this.lessonLayouts) : ld.cloneDeep(this.lessonLayouts.filter((layout: any) => layout.teacherId === 0));
      this.localLessonLayouts = this.localLessonLayouts.map((layout: any) => {
        layout.level = this.getLessonLayoutLevel(layout);
        layout.selectable = (this.$currentUser.isAdmin && layout.teacherId === 0) || (!this.$currentUser.isAdmin && layout.teacherId > 0 && layout.lessonLayoutId !== -1);
        layout.current = (this.$currentUser.isTeacher && this.currentYear.lessonLayoutId === layout.lessonLayoutId) || (this.$currentUser.isTeacher && this.currentYear.lessonLayoutId === 0 && layout.lessonLayoutId === -1) ? 'Y' : 'N';
        return layout;
      });
    }).finally(() => {
      this.localRefreshKey = CommonUtils.generateUUID();
      firstLoad ? CommonUtils.hideLoading() : this.localListLoading = false;
    });
    this.localAdminSelectedTab = (this.$currentUser.isAdmin && this.userType === 'D' ? 1 : 0)
  }
}
