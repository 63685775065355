var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-sheet",
    {
      staticClass: "reminders",
      staticStyle: { "overflow-y": "auto" },
      attrs: { color: "#ffffff", "min-height": "100vh" },
    },
    [
      !_vm.setupWizard && _vm.isSystemLock
        ? _c(
            "v-banner",
            {
              style: {
                "padding-left": _vm.$vuetify.breakpoint.smAndDown
                  ? "0"
                  : "81" + "px",
                color: "#055160",
              },
              attrs: { color: "#cff4fc" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("v-icon", { attrs: { color: "primary" } }, [
                          _vm._v("fas fa-circle-info"),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1131883331
              ),
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("systemLockInfoMessage")))])]
          )
        : _vm._e(),
      _c("div", {
        ref: "styleContainer",
        domProps: { innerHTML: _vm._s(_vm.styles) },
      }),
      _vm.isEmbedded
        ? _c(
            "v-toolbar",
            { attrs: { height: "56px", elevation: "0" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                { attrs: { dense: "", elevation: "0", to: "/signOut" } },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "list-item-icon",
                      attrs: { color: "info lighten-1" },
                    },
                    [_vm._v(" fal fa-sign-out-alt ")]
                  ),
                  _c("span", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.$t("signOutLabel"))),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.setupWizard
        ? _c(
            "v-container",
            {
              staticStyle: { height: "80vh" },
              attrs: { "fill-height": "", fluid: "" },
            },
            [
              _vm.isConnected
                ? _c(
                    "v-tabs",
                    {
                      staticClass: "pt-5",
                      attrs: {
                        "center-active": !_vm.$vuetify.breakpoint.mdAndUp,
                        centered: !_vm.$vuetify.breakpoint.mdAndUp,
                        vertical: _vm.$vuetify.breakpoint.mdAndUp,
                        "show-arrows": "",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          staticClass: "justify-start",
                          attrs: { disabled: !_vm.isSyncDone },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fal fa-school"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("orgMappinResultLabel"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "justify-start",
                          attrs: { disabled: !_vm.isSyncDone },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fal fa-chalkboard-user"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("teacherMappinResultLabel"))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "justify-start",
                          attrs: { disabled: !_vm.isSyncDone },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fal fa-calendar-days"),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("schoolYearMappinResultLabel"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        {
                          staticClass: "justify-start",
                          attrs: { disabled: !_vm.isSyncDone },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fal fa-gears"),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.$t("settingsLabel")))]),
                        ],
                        1
                      ),
                      _c(
                        "v-tab",
                        { staticClass: "justify-start" },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("fal fa-bars-progress"),
                          ]),
                          _c("span", [_vm._v(_vm._s(_vm.$t("statusLabel")))]),
                        ],
                        1
                      ),
                      _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  width: "100%",
                                  "max-width": "1300px",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "px-0 py-0" },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    clearable: "",
                                                    "single-line": "",
                                                    "hide-details": "",
                                                    "append-icon":
                                                      "fal fa-search",
                                                    label:
                                                      _vm.$t("searchLabel"),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.orgMappingSetUpSearch,
                                                    callback: function ($$v) {
                                                      _vm.orgMappingSetUpSearch =
                                                        $$v
                                                    },
                                                    expression:
                                                      "orgMappingSetUpSearch",
                                                  },
                                                }),
                                                _c("v-data-table", {
                                                  attrs: {
                                                    headers:
                                                      _vm.headersManageMapping,
                                                    items:
                                                      _vm.manageMappingOrgsArray,
                                                    search:
                                                      _vm.orgMappingSetUpSearch,
                                                    height:
                                                      "calc(80vh - 220px)",
                                                    "fixed-header": "",
                                                    "show-select":
                                                      !_vm.isSystemLock,
                                                    "item-key": "sourcedId",
                                                    "selectable-key":
                                                      "isRowSelectable",
                                                  },
                                                  on: {
                                                    "item-selected": (
                                                      item,
                                                      value
                                                    ) => {
                                                      _vm.checkBoxOrgClick(item)
                                                    },
                                                    "toggle-select-all": (
                                                      item,
                                                      value
                                                    ) => {
                                                      _vm.orgsToggleAllCheckBox(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: `header.data-table-select`,
                                                        fn: function ({
                                                          on,
                                                          props,
                                                        }) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "syncLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-simple-checkbox",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "gray",
                                                                      disabled:
                                                                        _vm.isSystemLock,
                                                                    },
                                                                  },
                                                                  "v-simple-checkbox",
                                                                  props,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.classLinkOrgName`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.classLinkOrgName
                                                                    )
                                                                  ),
                                                                ]),
                                                                !_vm.isActive(
                                                                  item.status
                                                                )
                                                                  ? _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          color:
                                                                            "error",
                                                                          "x-small":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "toBeDeletedLabel"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                item.type ==
                                                                "district"
                                                                  ? _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          "x-small":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "districtLabel"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.manageMappingPBSchoolName`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  justify:
                                                                    "center",
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                item.type !==
                                                                "district"
                                                                  ? _c(
                                                                      "v-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          disabled:
                                                                            item.dropDownDisabled,
                                                                          "hide-details":
                                                                            "auto",
                                                                          rules:
                                                                            _vm.localRules,
                                                                          items:
                                                                            item.planbookListArray,
                                                                          value:
                                                                            item.manageMappingPBSchoolName,
                                                                          "item-text":
                                                                            "displayValue",
                                                                          "item-value":
                                                                            "value",
                                                                          dense:
                                                                            "",
                                                                          readonly:
                                                                            _vm.isSystemLock,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.triggerIsDirtyCheckOrgsSelect(
                                                                                item,
                                                                                $event
                                                                              )
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.preventEmptyOrgs(
                                                                              item,
                                                                              "not"
                                                                            )
                                                                          },
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.orgsPreviousSavedValueSetter(
                                                                                item.manageMappingPBSchoolName
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : item.manageMappingPBSchoolName ===
                                                                    0
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "noDistrictLabel"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ])
                                                                  : _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item
                                                                            .manageMappingPBSchoolName
                                                                            .displayValue
                                                                        )
                                                                      ),
                                                                    ]),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `footer.prepend`,
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "totalNoOfSelectedItemsLabel"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .manageMappingOrgsSelected
                                                                      .length
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.manageMappingOrgsSelected,
                                                    callback: function ($$v) {
                                                      _vm.manageMappingOrgsSelected =
                                                        $$v
                                                    },
                                                    expression:
                                                      "manageMappingOrgsSelected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.isSystemLock
                                  ? _c(
                                      "v-card-actions",
                                      { staticClass: "justify-end mr-8" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "175px",
                                              disabled:
                                                _vm.isManageMappingOrgsNotDirty ||
                                                _vm.isSystemLock,
                                              color: "error",
                                            },
                                            on: {
                                              click:
                                                _vm.connectedOrgMappingSave,
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("saveLabel"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  width: "100%",
                                  "max-width": "1300px",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  {
                                    staticClass: "px-0 py-0",
                                    staticStyle: {
                                      "max-height": "calc(100vh - 180px)",
                                      "overflow-y": "auto",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mb-2",
                                                  attrs: {
                                                    clearable: "",
                                                    "single-line": "",
                                                    "hide-details": "",
                                                    "append-icon":
                                                      "fal fa-search",
                                                    label:
                                                      _vm.$t("searchLabel"),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.teacherMappingSetUpSearch,
                                                    callback: function ($$v) {
                                                      _vm.teacherMappingSetUpSearch =
                                                        $$v
                                                    },
                                                    expression:
                                                      "teacherMappingSetUpSearch",
                                                  },
                                                }),
                                                _c("v-data-table", {
                                                  attrs: {
                                                    headers:
                                                      _vm.headersManageMappingTeachers,
                                                    items:
                                                      _vm.connectedTeachersArray,
                                                    search:
                                                      _vm.teacherMappingSetUpSearch,
                                                    height:
                                                      "calc(80vh - 220px)",
                                                    "fixed-header": "",
                                                    "show-select":
                                                      !_vm.isSystemLock,
                                                    "item-key": "sourcedId",
                                                    "selectable-key":
                                                      "isRowSelectable",
                                                  },
                                                  on: {
                                                    "item-selected": (
                                                      item,
                                                      value
                                                    ) => {
                                                      _vm.checkBoxTeacherClick(
                                                        item
                                                      )
                                                    },
                                                    "toggle-select-all": (
                                                      item,
                                                      value
                                                    ) => {
                                                      _vm.teachersToggleAllCheckBox(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: `header.data-table-select`,
                                                        fn: function ({
                                                          on,
                                                          props,
                                                        }) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "syncLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-simple-checkbox",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "gray",
                                                                      disabled:
                                                                        _vm.isSystemLock,
                                                                    },
                                                                  },
                                                                  "v-simple-checkbox",
                                                                  props,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.classLinkTeacherName`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              [
                                                                _vm.hasValue(
                                                                  _vm.getClasslinkListItems(
                                                                    item
                                                                  )
                                                                ) &&
                                                                _vm.isNotEmpty(
                                                                  _vm.getClasslinkListItems(
                                                                    item
                                                                  )
                                                                )
                                                                  ? _c(
                                                                      "v-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          "hide-details":
                                                                            "auto",
                                                                          rules:
                                                                            _vm.localRules,
                                                                          items:
                                                                            _vm.getClasslinkListItems(
                                                                              item
                                                                            ),
                                                                          value:
                                                                            item.classlinkTeacherValue,
                                                                          "item-text":
                                                                            "displayValue",
                                                                          "item-value":
                                                                            "value",
                                                                          dense:
                                                                            "",
                                                                          readonly:
                                                                            _vm.isSystemLock,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.onClasslinkDuplicateTeacherChange(
                                                                                item,
                                                                                $event
                                                                              )
                                                                            },
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "item",
                                                                                fn: function (
                                                                                  data
                                                                                ) {
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            data
                                                                                              .item
                                                                                              .displayValue
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    data
                                                                                      .item
                                                                                      .disabled
                                                                                      ? _c(
                                                                                          "v-chip",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ml-2",
                                                                                            attrs:
                                                                                              {
                                                                                                color:
                                                                                                  "error",
                                                                                                "x-small":
                                                                                                  "",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "toBeDeletedLabel"
                                                                                                  )
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                              {
                                                                                key: "append-outer",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "pb-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "accent4",
                                                                                            label:
                                                                                              _vm.$t(
                                                                                                "duplicateTeacherMsg"
                                                                                              ),
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fal fa-circle-exclamation"
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.classLinkTeacherName
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        !_vm.isActive(
                                                                          item.status
                                                                        )
                                                                          ? _c(
                                                                              "v-chip",
                                                                              {
                                                                                staticClass:
                                                                                  "ml-2",
                                                                                attrs:
                                                                                  {
                                                                                    color:
                                                                                      "error",
                                                                                    "x-small":
                                                                                      "",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "toBeDeletedLabel"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.orgNames`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  justify:
                                                                    "center",
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  _vm._l(
                                                                    item.orgNames,
                                                                    function (
                                                                      loopItem,
                                                                      i
                                                                    ) {
                                                                      return _c(
                                                                        "span",
                                                                        {
                                                                          key: i,
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                loopItem
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          i +
                                                                            1 <
                                                                          item
                                                                            .orgNames
                                                                            .length
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    ", "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.manageMappingPBTeacherName`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  justify:
                                                                    "center",
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-autocomplete",
                                                                  {
                                                                    attrs: {
                                                                      outlined:
                                                                        "",
                                                                      disabled:
                                                                        item.dropDownDisabled,
                                                                      "hide-details":
                                                                        "auto",
                                                                      rules:
                                                                        _vm.localRules,
                                                                      items:
                                                                        item.planbookListArray,
                                                                      value:
                                                                        item.manageMappingPBTeacherName,
                                                                      "item-text":
                                                                        "displayValue",
                                                                      "item-value":
                                                                        "value",
                                                                      dense: "",
                                                                      readonly:
                                                                        _vm.isSystemLock,
                                                                    },
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.triggerIsDirtyCheckTeachersSelect(
                                                                            item,
                                                                            $event
                                                                          )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.preventEmptyTeachers(
                                                                          item,
                                                                          "not"
                                                                        )
                                                                      },
                                                                      keydown:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.teachersPreviousSavedValueSetter(
                                                                            item.manageMappingPBTeacherName
                                                                          )
                                                                        },
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "append-outer",
                                                                            fn: function () {
                                                                              return [
                                                                                item
                                                                                  .manageMappingPBTeacherName
                                                                                  .value &&
                                                                                _vm.isTeacherRemoved(
                                                                                  item
                                                                                    .manageMappingPBTeacherName
                                                                                    .value
                                                                                    .teacherId
                                                                                )
                                                                                  ? _c(
                                                                                      "pb-icon",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "warning",
                                                                                            label:
                                                                                              _vm.$t(
                                                                                                "reconnectTeacherToSchoolMsg"
                                                                                              ),
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fal fa-circle-exclamation"
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            },
                                                                            proxy: true,
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `footer.prepend`,
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "totalNoOfSelectedItemsLabel"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .manageMappingTeachersSelected
                                                                      .length
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.manageMappingTeachersSelected,
                                                    callback: function ($$v) {
                                                      _vm.manageMappingTeachersSelected =
                                                        $$v
                                                    },
                                                    expression:
                                                      "manageMappingTeachersSelected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.isSystemLock
                                  ? _c(
                                      "v-card-actions",
                                      { staticClass: "justify-end mr-8" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              width: "175px",
                                              disabled:
                                                _vm.isManageMappingTeachersNotDirty ||
                                                _vm.isSystemLock,
                                              color: "error",
                                            },
                                            on: {
                                              click:
                                                _vm.connectedOpenConfirmationModal,
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("saveLabel"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  width: "100%",
                                  "max-width": "1300px",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "px-0 py-0" },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    !_vm.isSystemLock
                                                      ? _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "auto",
                                                            },
                                                          },
                                                          [
                                                            !_vm.isSchoolYearWithoutClassesHidden
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      elevation:
                                                                        "0",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.hideSchoolYearWithoutClasses()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-eye-slash"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "hideSchoolYearWithoutClassesLabel"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _vm.isSchoolYearWithoutClassesHidden
                                                              ? _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "mt-2",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      elevation:
                                                                        "0",
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.showSchoolYearWithoutClasses()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        staticClass:
                                                                          "mr-1",
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "fal fa-eye"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "showSchoolYearWithoutClassesLabel"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !_vm.isSystemLock
                                                      ? _c("v-spacer")
                                                      : _vm._e(),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: _vm.isSystemLock
                                                            ? "12"
                                                            : "9",
                                                          sm: _vm.isSystemLock
                                                            ? "12"
                                                            : "6",
                                                          xs: _vm.isSystemLock
                                                            ? "12"
                                                            : "2",
                                                        },
                                                      },
                                                      [
                                                        _c("v-text-field", {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            clearable: "",
                                                            "single-line": "",
                                                            "hide-details": "",
                                                            "append-icon":
                                                              "fal fa-search",
                                                            label:
                                                              _vm.$t(
                                                                "searchLabel"
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.academicSessionMappingSetUpSearch,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.academicSessionMappingSetUpSearch =
                                                                $$v
                                                            },
                                                            expression:
                                                              "academicSessionMappingSetUpSearch",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c("v-data-table", {
                                                  attrs: {
                                                    height:
                                                      "calc(80vh - 220px)",
                                                    "fixed-header": "",
                                                    "show-select":
                                                      !_vm.isSystemLock,
                                                    search:
                                                      _vm.academicSessionMappingSetUpSearch,
                                                    headers:
                                                      _vm.headersManageMappingSchoolYears,
                                                    items:
                                                      _vm.manageMappingSchoolYearsArray,
                                                    "selectable-key":
                                                      "isRowSelectable",
                                                    "item-key": "sourcedId",
                                                  },
                                                  on: {
                                                    "item-selected": (
                                                      item,
                                                      value
                                                    ) => {
                                                      _vm.triggerIsDirtyCheckSchoolYearsArchive(
                                                        item
                                                      )
                                                    },
                                                    "toggle-select-all": (
                                                      item,
                                                      value
                                                    ) => {
                                                      _vm.schoolYearsToggleAllCheckBox(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: `header.data-table-select`,
                                                        fn: function ({
                                                          on,
                                                          props,
                                                        }) {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "syncLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "v-simple-checkbox",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      color:
                                                                        "gray",
                                                                      disabled:
                                                                        _vm.isSystemLock,
                                                                    },
                                                                  },
                                                                  "v-simple-checkbox",
                                                                  props,
                                                                  false
                                                                ),
                                                                on
                                                              )
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.classlinkSchoolYearName`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.classlinkSchoolYearName
                                                                    )
                                                                  ),
                                                                ]),
                                                                !_vm.isActive(
                                                                  item.status
                                                                )
                                                                  ? _c(
                                                                      "v-chip",
                                                                      {
                                                                        staticClass:
                                                                          "ml-2",
                                                                        attrs: {
                                                                          color:
                                                                            "error",
                                                                          "x-small":
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "toBeDeletedLabel"
                                                                              )
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.classlinkFirstAndLastDay`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  justify:
                                                                    "center",
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.formatToDisplay(
                                                                        _vm.formatDate(
                                                                          item.startDate
                                                                        ),
                                                                        true
                                                                      )
                                                                    ) +
                                                                      " - " +
                                                                      _vm._s(
                                                                        _vm.formatToDisplay(
                                                                          _vm.formatDate(
                                                                            item.endDate
                                                                          ),
                                                                          true
                                                                        )
                                                                      )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `item.manageMappingPBSchoolYearName`,
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  justify:
                                                                    "center",
                                                                  align:
                                                                    "center",
                                                                },
                                                              },
                                                              [
                                                                item.withClasses &&
                                                                item.yearId ===
                                                                  0
                                                                  ? _c(
                                                                      "v-autocomplete",
                                                                      {
                                                                        attrs: {
                                                                          outlined:
                                                                            "",
                                                                          "hide-details":
                                                                            "auto",
                                                                          rules:
                                                                            _vm.localRules,
                                                                          items:
                                                                            item.planBookSchoolYearArray,
                                                                          value:
                                                                            item.manageMappingPBSchoolYearName,
                                                                          "item-text":
                                                                            "displayValue",
                                                                          "item-value":
                                                                            "value",
                                                                          disabled:
                                                                            !item.withClasses ||
                                                                            item.dropDownDisabled,
                                                                          dense:
                                                                            "",
                                                                          readonly:
                                                                            _vm.isSystemLock,
                                                                        },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.triggerIsDirtyCheckSchoolYearsSelect(
                                                                                item,
                                                                                $event
                                                                              )
                                                                            },
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.preventEmptySchoolYears(
                                                                              item,
                                                                              "not"
                                                                            )
                                                                          },
                                                                          keydown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.schoolYearsPreviousSavedValueSetter(
                                                                                item.manageMappingPBSchoolYearName
                                                                              )
                                                                            },
                                                                        },
                                                                      }
                                                                    )
                                                                  : item.yearId !==
                                                                    0
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.manageMappingPBSchoolYearName
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : !item.withClasses
                                                                  ? _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "noClassesAssignedLabel"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ])
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                      {
                                                        key: `footer.prepend`,
                                                        fn: function () {
                                                          return [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "totalNoOfSelectedItemsLabel"
                                                                  )
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .manageMappingSchoolYearsSelected
                                                                      .length
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.manageMappingSchoolYearsSelected,
                                                    callback: function ($$v) {
                                                      _vm.manageMappingSchoolYearsSelected =
                                                        $$v
                                                    },
                                                    expression:
                                                      "manageMappingSchoolYearsSelected",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.isSystemLock
                                  ? _c(
                                      "v-card-actions",
                                      { staticClass: "justify-end mr-8" },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.isManageMappingSchoolYearsNotDirty ||
                                                _vm.isSystemLock,
                                              width: "175px",
                                              color: "error",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.connectedSchoolYearMappingSave(
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("saveLabel"))
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  width: "100%",
                                  "max-width": "800px",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "px-0 py-0" },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    attrs: {
                                                      height:
                                                        "calc(80vh - 100px)",
                                                      elevation: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass: "my-8",
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "classLinkEndPointLabel"
                                                        ),
                                                        outlined: "",
                                                        clearable:
                                                          !_vm.isSystemLock,
                                                        "hide-details": "auto",
                                                        rules: _vm.localRules,
                                                        readonly:
                                                          _vm.isSystemLock,
                                                      },
                                                      on: {
                                                        keydown:
                                                          _vm.triggerIsDirtyCheckSettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.connectedEndPoint,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.connectedEndPoint =
                                                            $$v
                                                        },
                                                        expression:
                                                          "connectedEndPoint",
                                                      },
                                                    }),
                                                    _c("v-text-field", {
                                                      staticClass: "my-8",
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "classLinkAccessKeyLabel"
                                                        ),
                                                        outlined: "",
                                                        clearable:
                                                          !_vm.isSystemLock,
                                                        "hide-details": "auto",
                                                        rules: _vm.localRules,
                                                        readonly:
                                                          _vm.isSystemLock,
                                                      },
                                                      on: {
                                                        keydown:
                                                          _vm.triggerIsDirtyCheckSettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.connectedAccessKey,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.connectedAccessKey =
                                                            $$v
                                                        },
                                                        expression:
                                                          "connectedAccessKey",
                                                      },
                                                    }),
                                                    _c("v-text-field", {
                                                      staticClass: "my-8",
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "classLinkSecretKeyLabel"
                                                        ),
                                                        outlined: "",
                                                        clearable:
                                                          !_vm.isSystemLock,
                                                        "hide-details": "auto",
                                                        rules: _vm.localRules,
                                                        readonly:
                                                          _vm.isSystemLock,
                                                      },
                                                      on: {
                                                        keydown:
                                                          _vm.triggerIsDirtyCheckSettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.connectedSecretKey,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.connectedSecretKey =
                                                            $$v
                                                        },
                                                        expression:
                                                          "connectedSecretKey",
                                                      },
                                                    }),
                                                    _c("v-select", {
                                                      staticClass: "my-8",
                                                      attrs: {
                                                        items:
                                                          _vm.connectedSyncTimeItems,
                                                        "item-text":
                                                          "timeToDisplay",
                                                        "item-value":
                                                          "timeValue",
                                                        label:
                                                          _vm.$t(
                                                            "synchTimeLabel"
                                                          ),
                                                        "hide-details": "auto",
                                                        outlined: "",
                                                        readonly:
                                                          _vm.isSystemLock,
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.triggerIsDirtyCheckSettings,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.connectedSyncTime,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.connectedSyncTime =
                                                            $$v
                                                        },
                                                        expression:
                                                          "connectedSyncTime",
                                                      },
                                                    }),
                                                    !_vm.isSystemLock
                                                      ? _c(
                                                          "v-card-actions",
                                                          {
                                                            staticClass:
                                                              "justify-end",
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  width:
                                                                    "175px",
                                                                  color:
                                                                    "error",
                                                                  disabled:
                                                                    _vm.isConnectedSettingsNotDirty ||
                                                                    _vm.isSystemLock,
                                                                },
                                                                on: {
                                                                  click:
                                                                    _vm.connectedSettingsSave,
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "saveLabel"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-tab-item", [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center" },
                          [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  flat: "",
                                  width: "100%",
                                  "max-width": "1300px",
                                },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  { staticClass: "px-0 py-0" },
                                  [
                                    _c(
                                      "v-container",
                                      { staticClass: "px-10" },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-card",
                                                  {
                                                    attrs: {
                                                      height:
                                                        "calc(80vh - 50px)",
                                                      elevation: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("v-data-table", {
                                                      attrs: {
                                                        headers:
                                                          _vm.statusHeaders,
                                                        items: _vm.statusArray,
                                                        height:
                                                          "calc(80vh - 340px)",
                                                        "fixed-header": "",
                                                        "item-key": "dataName",
                                                        "hide-default-footer":
                                                          "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: `item.dataName`,
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _c("td", [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.dataName
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: `item.status`,
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _c("td", [
                                                                  item.dataName ==
                                                                  "Orgs"
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm
                                                                            .syncStatusObject
                                                                            .orgs ==
                                                                          1
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      indeterminate:
                                                                                        "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .orgs ==
                                                                              2
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "success",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-check"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .orgs ==
                                                                              0
                                                                            ? _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "error",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-x"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item.dataName ==
                                                                  "Users"
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm
                                                                            .syncStatusObject
                                                                            .users ==
                                                                          1
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      indeterminate:
                                                                                        "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .users ==
                                                                              2
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "success",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-check"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .users ==
                                                                              0
                                                                            ? _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "error",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-x"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item.dataName ==
                                                                  "Academic Sessions"
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm
                                                                            .syncStatusObject
                                                                            .sessions ==
                                                                          1
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      indeterminate:
                                                                                        "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .sessions ==
                                                                              2
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "success",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-check"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .sessions ==
                                                                              0
                                                                            ? _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "error",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-x"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item.dataName ==
                                                                  "Enrollments"
                                                                    ? _c(
                                                                        "div",
                                                                        [
                                                                          _vm
                                                                            .syncStatusObject
                                                                            .enrollments ==
                                                                          1
                                                                            ? _c(
                                                                                "v-progress-circular",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      indeterminate:
                                                                                        "",
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                }
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .enrollments ==
                                                                              2
                                                                            ? _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "success",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-check"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm
                                                                                .syncStatusObject
                                                                                .enrollments ==
                                                                              0
                                                                            ? _c(
                                                                                "v-icon",
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle"
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "v-icon",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "error",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "fal fa-circle-x"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                              ]
                                                            },
                                                          },
                                                          {
                                                            key: `item.lastSync`,
                                                            fn: function ({
                                                              item,
                                                            }) {
                                                              return [
                                                                _c("td", [
                                                                  item.dataName ==
                                                                  "Orgs"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .syncStatusDate
                                                                                .orgs
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : item.dataName ==
                                                                      "Users"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .syncStatusDate
                                                                                .users
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : item.dataName ==
                                                                      "Academic Sessions"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .syncStatusDate
                                                                                .sessions
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : item.dataName ==
                                                                      "Enrollments"
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm
                                                                                .syncStatusDate
                                                                                .enrollments
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    }),
                                                    !_vm.isSystemLock
                                                      ? _c("v-divider", {
                                                          staticClass: "mb-5",
                                                        })
                                                      : _vm._e(),
                                                    !_vm.isSystemLock
                                                      ? _c(
                                                          "v-card-actions",
                                                          {
                                                            staticClass:
                                                              "justify-center",
                                                          },
                                                          [
                                                            _c(
                                                              "v-row",
                                                              {
                                                                staticClass:
                                                                  "justify-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "auto",
                                                                      sm: "6",
                                                                      xl: "auto",
                                                                      lg: "auto",
                                                                      md: "auto",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.isConnected
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mx-5",
                                                                            class:
                                                                              {
                                                                                "pa-7 mb-3 ml-16":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .xsOnly,
                                                                                "pa-10 mb-3 ml-14":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .smOnly,
                                                                                "pa-10":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .mdAndUp,
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.isSyncDone,
                                                                                color:
                                                                                  "primary",
                                                                                text: "",
                                                                                outlined:
                                                                                  "",
                                                                                "x-large":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.syncNow,
                                                                            },
                                                                          },
                                                                          [
                                                                            !_vm.isSyncDone
                                                                              ? _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fal fa-sync fa-spin"
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "fal fa-sync"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "syncNowLabel"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isConnected &&
                                                                    !_vm.isDisconnected
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mx-5",
                                                                            class:
                                                                              {
                                                                                "pa-7 ml-11":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .xsOnly,
                                                                                "pa-10 ml-8":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .smOnly,
                                                                                "pa-10":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .mdAndUp,
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.isSyncDone,
                                                                                color:
                                                                                  "error",
                                                                                text: "",
                                                                                outlined:
                                                                                  "",
                                                                                "x-large":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.disconnectClassLink,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-img",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    width:
                                                                                      "60",
                                                                                    height:
                                                                                      "100",
                                                                                    src: require("@/components/customicons/classlinkicon.png"),
                                                                                    alt: "classlink_logo",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "disconnectClassLinkLabel"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.isDisconnected
                                                                      ? _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mx-5",
                                                                            class:
                                                                              {
                                                                                "pa-7 ml-11":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .xsOnly,
                                                                                "pa-10 ml-8":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .smOnly,
                                                                                "pa-10":
                                                                                  _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .mdAndUp,
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                disabled:
                                                                                  !_vm.isSyncDone,
                                                                                color:
                                                                                  "primary",
                                                                                text: "",
                                                                                outlined:
                                                                                  "",
                                                                                "x-large":
                                                                                  "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                _vm.reconnectClassLink,
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-img",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  },
                                                                                attrs:
                                                                                  {
                                                                                    width:
                                                                                      "60",
                                                                                    height:
                                                                                      "90",
                                                                                    src: require("@/components/customicons/classlinkicon.png"),
                                                                                    alt: "classlink_logo",
                                                                                  },
                                                                              }
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "mx-1",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "reconnectClassLinkLabel"
                                                                                    )
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.pageInitializing
                ? _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { col: "", cols: "auto" } },
                        [
                          !_vm.setupWizard &&
                          !_vm.isConnected &&
                          _vm.isAllowedToSetUp
                            ? _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ hover }) {
                                                return [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      class: {
                                                        "pa-7":
                                                          _vm.$vuetify
                                                            .breakpoint.xsOnly,
                                                        "pa-16":
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp,
                                                      },
                                                      attrs: {
                                                        elevation: hover
                                                          ? 10
                                                          : 3,
                                                        color: _vm.darkMode
                                                          ? ""
                                                          : "#ffffff",
                                                      },
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        staticClass:
                                                          "ma-auto mb-5",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          width: "130",
                                                          height: "60",
                                                          src: require("@/components/customicons/classlinkicon.png"),
                                                          alt: "classlink_logo",
                                                        },
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          class: {
                                                            "pa-3":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .smAndDown,
                                                            "pa-10":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp,
                                                          },
                                                          attrs: {
                                                            color: "primary",
                                                            outlined: "",
                                                            "x-large": "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.setupWizard = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "integrateClassLinkLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4012683759
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c("v-hover", {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ hover }) {
                                                return [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      class: {
                                                        "pa-7":
                                                          _vm.$vuetify
                                                            .breakpoint.xsOnly,
                                                        "pa-16":
                                                          _vm.$vuetify
                                                            .breakpoint.smAndUp,
                                                      },
                                                      attrs: {
                                                        elevation: hover
                                                          ? 10
                                                          : 3,
                                                        color: _vm.darkMode
                                                          ? ""
                                                          : "#ffffff",
                                                      },
                                                    },
                                                    [
                                                      _c("v-img", {
                                                        staticClass:
                                                          "ma-auto mb-5",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                        attrs: {
                                                          width: "60",
                                                          height: "60",
                                                          src: require("@/components/customicons/request_icon.png"),
                                                          alt: "request_logo",
                                                        },
                                                      }),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          class: {
                                                            "pa-3":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .smAndDown,
                                                            "pa-10":
                                                              _vm.$vuetify
                                                                .breakpoint
                                                                .mdAndUp,
                                                          },
                                                          attrs: {
                                                            color: "primary",
                                                            outlined: "",
                                                            "x-large": "",
                                                            href: "https://planbook.uservoice.com/forums/146191-general",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "mx-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "requestIntegrationsLabel"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3864216259
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.setupWizard &&
                          !_vm.isConnected &&
                          !_vm.isAllowedToSetUp
                            ? _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "pa-16",
                                          attrs: {
                                            color: _vm.darkMode
                                              ? ""
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _c(
                                            "h3",
                                            { staticClass: "text-uppercase" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "notDistrictAdminLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.setupWizard
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-10",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "11" } },
                    [
                      _c(
                        "v-stepper",
                        {
                          attrs: {
                            elevation: "0",
                            "alt-labels": "",
                            id: "stepper",
                          },
                          model: {
                            value: _vm.setupStepCount,
                            callback: function ($$v) {
                              _vm.setupStepCount = $$v
                            },
                            expression: "setupStepCount",
                          },
                        },
                        [
                          _c(
                            "v-stepper-header",
                            { staticClass: "elevation-0" },
                            [
                              _c("v-stepper-step", { attrs: { step: "1" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("integrateClassLinkLabel")) +
                                    " "
                                ),
                              ]),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "2" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("orgMappingResultLabel")) +
                                    " "
                                ),
                              ]),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "3" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("teacherMappingResultLabel")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "4" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("schoolYearsMappingResultLabel")
                                    ) +
                                    " "
                                ),
                              ]),
                              _c("v-divider"),
                              _c("v-stepper-step", { attrs: { step: "5" } }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("synchTimeLabel")) + " "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-items",
                            [
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "1" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12",
                                      attrs: {
                                        height: _vm.stepperHeight,
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "7" } },
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  ref: "integrationStep1Form",
                                                  attrs: {
                                                    "lazy-validation": "",
                                                  },
                                                  model: {
                                                    value: _vm.validStep2,
                                                    callback: function ($$v) {
                                                      _vm.validStep2 = $$v
                                                    },
                                                    expression: "validStep2",
                                                  },
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    staticClass: "my-8",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "classLinkEndPointLabel"
                                                      ),
                                                      outlined: "",
                                                      clearable: "",
                                                      "hide-details": "auto",
                                                      rules: _vm.localRules,
                                                    },
                                                    model: {
                                                      value: _vm.setUpEndPoint,
                                                      callback: function ($$v) {
                                                        _vm.setUpEndPoint = $$v
                                                      },
                                                      expression:
                                                        "setUpEndPoint",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    staticClass: "my-8",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "classLinkAccessKeyLabel"
                                                      ),
                                                      outlined: "",
                                                      clearable: "",
                                                      "hide-details": "auto",
                                                      rules: _vm.localRules,
                                                    },
                                                    model: {
                                                      value: _vm.setUpAccessKey,
                                                      callback: function ($$v) {
                                                        _vm.setUpAccessKey = $$v
                                                      },
                                                      expression:
                                                        "setUpAccessKey",
                                                    },
                                                  }),
                                                  _c("v-text-field", {
                                                    staticClass: "my-8",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "classLinkSecretKeyLabel"
                                                      ),
                                                      outlined: "",
                                                      clearable: "",
                                                      "hide-details": "auto",
                                                      rules: _vm.localRules,
                                                    },
                                                    model: {
                                                      value: _vm.setUpSecretKey,
                                                      callback: function ($$v) {
                                                        _vm.setUpSecretKey = $$v
                                                      },
                                                      expression:
                                                        "setUpSecretKey",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.cancelSetUp()
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("cancelLabel")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                dark: "",
                                                depressed: "",
                                                "x-large": "",
                                                elevation: "0",
                                              },
                                              on: { click: _vm.step1Validate },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "continueIntegrationLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-overlay",
                                {
                                  attrs: {
                                    value: _vm.overlay,
                                    "z-index": "50",
                                  },
                                },
                                [
                                  _vm.overlay && _vm.walkthrough1
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass: "tooltip-bottom",
                                          staticStyle: { position: "fixed" },
                                          style: {
                                            left: _vm.getWalkthrough1Left,
                                            top: _vm.getWalkthrough1Top,
                                          },
                                          attrs: {
                                            id: "tooltipCard",
                                            "max-width": "180",
                                            color: _vm.darkMode
                                              ? ""
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              class: _vm.darkMode
                                                ? ""
                                                : "black--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("walkthrough1Label")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.skipWalkthrough,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("skipLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.walkthrough2 = true),
                                                        (_vm.walkthrough1 = false)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("nextLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass: "arrow-down",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.overlay && _vm.walkthrough2
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass: "tooltip-bottom",
                                          staticStyle: { position: "fixed" },
                                          style: {
                                            left: _vm.getWalkthrough2Left,
                                            top: _vm.getWalkthrough2Top,
                                          },
                                          attrs: {
                                            id: "tooltipCard",
                                            "max-width": "120",
                                            color: _vm.darkMode
                                              ? ""
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              class: _vm.darkMode
                                                ? ""
                                                : "black--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("walkthrough2Label")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.skipWalkthrough,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("skipLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.walkthrough3 = true),
                                                        (_vm.walkthrough2 = false)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("nextLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.isEmbedded ||
                                          this.$vuetify.breakpoint.name === "sm"
                                            ? _c("div", {
                                                staticClass: "arrow-up",
                                              })
                                            : _c("div", {
                                                staticClass: "arrow-right",
                                              }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.overlay && _vm.walkthrough3
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass: "tooltip-bottom",
                                          staticStyle: { position: "fixed" },
                                          style: {
                                            left: _vm.getWalkthrough3Left,
                                            bottom: _vm.getWalkthrough3Bottom,
                                          },
                                          attrs: {
                                            id: "tooltipCard",
                                            "max-width": "250",
                                            color: _vm.darkMode
                                              ? ""
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              class: _vm.darkMode
                                                ? ""
                                                : "black--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("walkthrough3Label")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.skipWalkthrough,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("skipLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.walkthrough4 = true),
                                                        (_vm.walkthrough3 = false)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("nextLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass: "arrow-down",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.overlay && _vm.walkthrough4
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass: "tooltip-bottom",
                                          staticStyle: { position: "fixed" },
                                          style: {
                                            right: _vm.getWalkthrough4Right,
                                            top: _vm.getWalkthrough4Top,
                                          },
                                          attrs: {
                                            id: "tooltipCard",
                                            "max-width": "250",
                                            color: _vm.darkMode
                                              ? ""
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              class: _vm.darkMode
                                                ? ""
                                                : "black--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("walkthrough4Label")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    small: "",
                                                    text: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: _vm.skipWalkthrough,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("skipLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.walkthrough5 = true),
                                                        (_vm.walkthrough4 = false)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("nextLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass: "arrow-down",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.overlay && _vm.walkthrough5
                                    ? _c(
                                        "v-card",
                                        {
                                          staticClass: "tooltip-bottom",
                                          staticStyle: {
                                            position: "fixed",
                                            bottom: "21%",
                                          },
                                          style: {
                                            right: _vm.getWalkthrough5Right,
                                          },
                                          attrs: {
                                            id: "tooltipCard",
                                            "max-width": "150",
                                            color: _vm.darkMode
                                              ? ""
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            {
                                              class: _vm.darkMode
                                                ? ""
                                                : "black--text",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("walkthrough5Label")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c("v-spacer"),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "success",
                                                    small: "",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      ;(_vm.overlay = false),
                                                        (_vm.walkthrough5 = false)
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("gotItLabel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("div", {
                                            staticClass: "arrow-down",
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "2" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12",
                                      attrs: {
                                        height: _vm.stepperHeight,
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-form",
                                                {
                                                  ref: "integrationStep2Form",
                                                  attrs: {
                                                    "lazy-validation": "",
                                                  },
                                                  model: {
                                                    value: _vm.validStep1,
                                                    callback: function ($$v) {
                                                      _vm.validStep1 = $$v
                                                    },
                                                    expression: "validStep1",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        align: "center",
                                                        justify: "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "auto",
                                                                  },
                                                                },
                                                                [
                                                                  !_vm.isOrgTableCompleteMatchHidden
                                                                    ? _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              elevation:
                                                                                "0",
                                                                              text: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.hideCompleteMatchOrgsSetup()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "fal fa-eye-slash"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "hideCompleteMatchLabel"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.isOrgTableCompleteMatchHidden
                                                                    ? _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "primary",
                                                                              elevation:
                                                                                "0",
                                                                              text: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.showCompleteMatchOrgsSetup()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-1",
                                                                              attrs:
                                                                                {
                                                                                  small:
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "fal fa-eye"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "showCompleteMatchLabel"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-spacer"),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "9",
                                                                    sm: "6",
                                                                    xs: "2",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "mb-2",
                                                                      attrs: {
                                                                        clearable:
                                                                          "",
                                                                        "single-line":
                                                                          "",
                                                                        "hide-details":
                                                                          "",
                                                                        "append-icon":
                                                                          "fal fa-search",
                                                                        label:
                                                                          _vm.$t(
                                                                            "searchLabel"
                                                                          ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.orgMappingSetUpSearch,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.orgMappingSetUpSearch =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "orgMappingSetUpSearch",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c("v-data-table", {
                                                            attrs: {
                                                              headers:
                                                                _vm.headersStep2,
                                                              search:
                                                                _vm.orgMappingSetUpSearch,
                                                              height:
                                                                "calc(80vh - 250px)",
                                                              loading:
                                                                _vm.isTableLoading,
                                                              "fixed-header":
                                                                "",
                                                              "loading-text":
                                                                _vm.$t(
                                                                  "listLoadingMsg"
                                                                ),
                                                              "show-select": "",
                                                              items:
                                                                _vm.orgsMappingArray,
                                                              "item-key":
                                                                "sourcedId",
                                                              "selectable-key":
                                                                "isRowSelectable",
                                                              "sort-by": [
                                                                "score",
                                                              ],
                                                            },
                                                            on: {
                                                              "item-selected": (
                                                                item,
                                                                value
                                                              ) => {
                                                                _vm.checkBoxOrgClick(
                                                                  item
                                                                )
                                                              },
                                                              "toggle-select-all":
                                                                (
                                                                  item,
                                                                  value
                                                                ) => {
                                                                  _vm.orgsToggleAllCheckBox(
                                                                    item
                                                                  )
                                                                },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: `header.data-table-select`,
                                                                  fn: function ({
                                                                    on,
                                                                    props,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "syncLabel"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "v-simple-checkbox",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "gray",
                                                                                },
                                                                            },
                                                                            "v-simple-checkbox",
                                                                            props,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: `item.setUpSchoolName`,
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "td",
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  item.setUpSchoolName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          !_vm.isActive(
                                                                            item.status
                                                                          )
                                                                            ? _c(
                                                                                "v-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-2",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "error",
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "toBeDeletedLabel"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          item.type ==
                                                                          "district"
                                                                            ? _c(
                                                                                "v-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-2",
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                      "x-small":
                                                                                        "",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "districtLabel"
                                                                                      )
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: `item.planBookOrgArray`,
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "td",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              justify:
                                                                                "center",
                                                                              align:
                                                                                "center",
                                                                            },
                                                                        },
                                                                        [
                                                                          item.type !==
                                                                          "district"
                                                                            ? _c(
                                                                                "v-autocomplete",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        item.dropDownDisabled,
                                                                                      outlined:
                                                                                        "",
                                                                                      "hide-details":
                                                                                        "auto",
                                                                                      rules:
                                                                                        _vm.localRules,
                                                                                      items:
                                                                                        item.planbookListArray,
                                                                                      value:
                                                                                        item.setUpPBSchoolName,
                                                                                      "item-text":
                                                                                        "displayValue",
                                                                                      "item-value":
                                                                                        "value",
                                                                                      dense:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    change:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.changeOrgsSelect(
                                                                                          item,
                                                                                          $event
                                                                                        )
                                                                                      },
                                                                                    blur: function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.preventEmptyOrgs(
                                                                                        item,
                                                                                        "setup"
                                                                                      )
                                                                                    },
                                                                                    keydown:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.orgsPreviousSavedValueSetter(
                                                                                          item.setUpPBSchoolName
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              )
                                                                            : item
                                                                                .setUpPBSchoolName
                                                                                .value
                                                                                .schoolId ===
                                                                              0
                                                                            ? _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "noDistrictLabel"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "span",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item
                                                                                        .setUpPBSchoolName
                                                                                        .displayValue
                                                                                    )
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: `item.score`,
                                                                  fn: function ({
                                                                    item,
                                                                  }) {
                                                                    return [
                                                                      _c("td", [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "text-align":
                                                                                  "center",
                                                                                margin:
                                                                                  "auto",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    item.score
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]),
                                                                    ]
                                                                  },
                                                                },
                                                                {
                                                                  key: `footer.prepend`,
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "totalNoOfSelectedItemsLabel"
                                                                              )
                                                                            ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .selectedOrgs
                                                                                  .length
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.selectedOrgs,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.selectedOrgs =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "selectedOrgs",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: { click: _vm.backStep2 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("backLabel"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                dark: !_vm.noDataFound,
                                                depressed: "",
                                                "x-large": "",
                                                elevation: "0",
                                                disabled: _vm.noDataFound,
                                              },
                                              on: { click: _vm.step2Validate },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "continueIntegrationLabel"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "3" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12",
                                      attrs: {
                                        height: _vm.stepperHeight,
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      !_vm.isTeacherTableCompleteMatchHidden
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                elevation: "0",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.hideCompleteMatchTeacherSetup()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-eye-slash"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "hideCompleteMatchLabel"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.isTeacherTableCompleteMatchHidden
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                elevation: "0",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showCompleteMatchTeacherSetup()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-eye"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "showCompleteMatchLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "9",
                                                        sm: "6",
                                                        xs: "2",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          clearable: "",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "fal fa-search",
                                                          label:
                                                            _vm.$t(
                                                              "searchLabel"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.teacherMappingSetUpSearch,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.teacherMappingSetUpSearch =
                                                              $$v
                                                          },
                                                          expression:
                                                            "teacherMappingSetUpSearch",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-data-table", {
                                                attrs: {
                                                  headers: _vm.headersStep3,
                                                  search:
                                                    _vm.teacherMappingSetUpSearch,
                                                  height: "calc(80vh - 250px)",
                                                  loading: _vm.isTableLoading,
                                                  "fixed-header": "",
                                                  "loading-text":
                                                    _vm.$t("listLoadingMsg"),
                                                  "show-select": "",
                                                  items:
                                                    _vm.teachersMappingArray,
                                                  "item-key": "sourcedId",
                                                  "sort-by": ["score"],
                                                  "selectable-key":
                                                    "isRowSelectable",
                                                },
                                                on: {
                                                  "item-selected": (
                                                    item,
                                                    value
                                                  ) => {
                                                    _vm.checkBoxTeacherClick(
                                                      item
                                                    )
                                                  },
                                                  "toggle-select-all": (
                                                    item,
                                                    value
                                                  ) => {
                                                    _vm.teachersToggleAllCheckBox(
                                                      item
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: `header.data-table-select`,
                                                      fn: function ({
                                                        on,
                                                        props,
                                                      }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "syncLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "v-simple-checkbox",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "gray",
                                                                  },
                                                                },
                                                                "v-simple-checkbox",
                                                                props,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.setUpTeacherName`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            [
                                                              _vm.hasValue(
                                                                _vm.getClasslinkListItems(
                                                                  item
                                                                )
                                                              ) &&
                                                              _vm.isNotEmpty(
                                                                _vm.getClasslinkListItems(
                                                                  item
                                                                )
                                                              )
                                                                ? _c(
                                                                    "v-autocomplete",
                                                                    {
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        "hide-details":
                                                                          "auto",
                                                                        rules:
                                                                          _vm.localRules,
                                                                        items:
                                                                          _vm.getClasslinkListItems(
                                                                            item
                                                                          ),
                                                                        value:
                                                                          item.classlinkTeacherValue,
                                                                        "item-text":
                                                                          "displayValue",
                                                                        "item-value":
                                                                          "value",
                                                                        dense:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.onClasslinkDuplicateTeacherChange(
                                                                              item,
                                                                              $event
                                                                            )
                                                                          },
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "item",
                                                                              fn: function (
                                                                                data
                                                                              ) {
                                                                                return [
                                                                                  _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          data
                                                                                            .item
                                                                                            .displayValue
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  data
                                                                                    .item
                                                                                    .disabled
                                                                                    ? _c(
                                                                                        "v-chip",
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-2",
                                                                                          attrs:
                                                                                            {
                                                                                              color:
                                                                                                "error",
                                                                                              "x-small":
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "toBeDeletedLabel"
                                                                                                )
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key: "append-outer",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "pb-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "accent4",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "duplicateTeacherMsg"
                                                                                            ),
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fal fa-circle-exclamation"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    }
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.setUpTeacherName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      !_vm.isActive(
                                                                        item.status
                                                                      )
                                                                        ? _c(
                                                                            "v-chip",
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "error",
                                                                                  "x-small":
                                                                                    "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "toBeDeletedLabel"
                                                                                    )
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.orgNames`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                justify:
                                                                  "center",
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                _vm._l(
                                                                  item.orgNames,
                                                                  function (
                                                                    loopItem,
                                                                    i
                                                                  ) {
                                                                    return _c(
                                                                      "span",
                                                                      {
                                                                        key: i,
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              loopItem
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                        i + 1 <
                                                                        item
                                                                          .orgNames
                                                                          .length
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  ", "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.setUpPBTeacherName`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                justify:
                                                                  "center",
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-autocomplete",
                                                                {
                                                                  attrs: {
                                                                    outlined:
                                                                      "",
                                                                    disabled:
                                                                      item.dropDownDisabled,
                                                                    "hide-details":
                                                                      "auto",
                                                                    rules:
                                                                      _vm.localRules,
                                                                    items:
                                                                      item.planbookListArray,
                                                                    value:
                                                                      item.setUpPBTeacherName,
                                                                    "item-text":
                                                                      "displayValue",
                                                                    "item-value":
                                                                      "value",
                                                                    dense: "",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeTeachersSelect(
                                                                          item,
                                                                          $event
                                                                        )
                                                                      },
                                                                    blur: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.preventEmptyTeachers(
                                                                        item,
                                                                        "setup"
                                                                      )
                                                                    },
                                                                    keydown:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.teachersPreviousSavedValueSetter(
                                                                          item.setUpPBTeacherName
                                                                        )
                                                                      },
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "append-outer",
                                                                          fn: function () {
                                                                            return [
                                                                              item
                                                                                .setUpPBTeacherName
                                                                                .value &&
                                                                              _vm.isTeacherRemoved(
                                                                                item
                                                                                  .setUpPBTeacherName
                                                                                  .value
                                                                                  .teacherId
                                                                              )
                                                                                ? _c(
                                                                                    "pb-icon",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          color:
                                                                                            "warning",
                                                                                          label:
                                                                                            _vm.$t(
                                                                                              "reconnectTeacherToSchoolMsg"
                                                                                            ),
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fal fa-circle-exclamation"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.score`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c("td", [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "text-align":
                                                                    "center",
                                                                  margin:
                                                                    "auto",
                                                                },
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.score
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `footer.prepend`,
                                                      fn: function () {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "totalNoOfSelectedItemsLabel"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedTeachers
                                                                    .length
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.selectedTeachers,
                                                  callback: function ($$v) {
                                                    _vm.selectedTeachers = $$v
                                                  },
                                                  expression:
                                                    "selectedTeachers",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: { click: _vm.backStep3 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("backLabel"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                dark: "",
                                                depressed: "",
                                                "x-large": "",
                                                elevation: "0",
                                              },
                                              on: { click: _vm.step3Validate },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "continueIntegrationLabel"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12",
                                      attrs: {
                                        height: _vm.stepperHeight,
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "auto" } },
                                                    [
                                                      !_vm.isSchoolYearWithoutClassesHidden
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                elevation: "0",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.hideSchoolYearWithoutClasses()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-eye-slash"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "hideSchoolYearWithoutClassesLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.isSchoolYearWithoutClassesHidden
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                elevation: "0",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showSchoolYearWithoutClasses()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "mr-1",
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "fal fa-eye"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "showSchoolYearWithoutClassesLabel"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      attrs: {
                                                        cols: "9",
                                                        sm: "6",
                                                        xs: "2",
                                                      },
                                                    },
                                                    [
                                                      _c("v-text-field", {
                                                        staticClass: "mb-2",
                                                        attrs: {
                                                          clearable: "",
                                                          "single-line": "",
                                                          "hide-details": "",
                                                          "append-icon":
                                                            "fal fa-search",
                                                          label:
                                                            _vm.$t(
                                                              "searchLabel"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.academicSessionMappingSetUpSearch,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.academicSessionMappingSetUpSearch =
                                                              $$v
                                                          },
                                                          expression:
                                                            "academicSessionMappingSetUpSearch",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("v-data-table", {
                                                attrs: {
                                                  headers: _vm.headersStep4,
                                                  search:
                                                    _vm.academicSessionMappingSetUpSearch,
                                                  height: "calc(80vh - 250px)",
                                                  loading: _vm.isTableLoading,
                                                  "fixed-header": "",
                                                  "loading-text":
                                                    _vm.$t("listLoadingMsg"),
                                                  "show-select": "",
                                                  items:
                                                    _vm.schoolYearsMappingArray,
                                                  "item-key": "sourcedId",
                                                  "selectable-key":
                                                    "isRowSelectable",
                                                },
                                                on: {
                                                  "item-selected": (
                                                    item,
                                                    value
                                                  ) => {
                                                    _vm.triggerIsDirtyCheckSchoolYearsArchive(
                                                      item
                                                    )
                                                  },
                                                  "toggle-select-all": (
                                                    item,
                                                    value
                                                  ) => {
                                                    _vm.schoolYearsToggleAllCheckBox(
                                                      item
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: `header.data-table-select`,
                                                      fn: function ({
                                                        on,
                                                        props,
                                                      }) {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "syncLabel"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "v-simple-checkbox",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    color:
                                                                      "gray",
                                                                  },
                                                                },
                                                                "v-simple-checkbox",
                                                                props,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.setUpSchoolYear`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.setUpSchoolYear
                                                                  )
                                                                ),
                                                              ]),
                                                              !_vm.isActive(
                                                                item.status
                                                              )
                                                                ? _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2",
                                                                      attrs: {
                                                                        color:
                                                                          "error",
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "toBeDeletedLabel"
                                                                            )
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.setUpFirstAndLastDay`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                justify:
                                                                  "center",
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.formatToDisplay(
                                                                      _vm.formatDate(
                                                                        item.startDate
                                                                      ),
                                                                      true
                                                                    )
                                                                  ) +
                                                                    " - " +
                                                                    _vm._s(
                                                                      _vm.formatToDisplay(
                                                                        _vm.formatDate(
                                                                          item.endDate
                                                                        ),
                                                                        true
                                                                      )
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `item.setUpPBSchoolYear`,
                                                      fn: function ({ item }) {
                                                        return [
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                justify:
                                                                  "center",
                                                                align: "center",
                                                              },
                                                            },
                                                            [
                                                              item.withClasses
                                                                ? _c(
                                                                    "v-autocomplete",
                                                                    {
                                                                      attrs: {
                                                                        outlined:
                                                                          "",
                                                                        "hide-details":
                                                                          "auto",
                                                                        rules:
                                                                          _vm.localRules,
                                                                        items:
                                                                          item.planBookSchoolYearArray,
                                                                        value:
                                                                          item.setUpPBSchoolYear,
                                                                        "item-text":
                                                                          "displayValue",
                                                                        "item-value":
                                                                          "value",
                                                                        disabled:
                                                                          !item.withClasses ||
                                                                          item.dropDownDisabled,
                                                                        dense:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.changeSchoolYearsSelect(
                                                                              item,
                                                                              $event
                                                                            )
                                                                          },
                                                                        blur: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.preventEmptySchoolYears(
                                                                            item,
                                                                            "setup"
                                                                          )
                                                                        },
                                                                        keydown:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.schoolYearsPreviousSavedValueSetter(
                                                                              item.setUpPBSchoolYear
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              !item.withClasses
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "noClassesAssignedLabel"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                    {
                                                      key: `footer.prepend`,
                                                      fn: function () {
                                                        return [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "totalNoOfSelectedItemsLabel"
                                                                )
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .selectedSchoolYears
                                                                    .length
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value:
                                                    _vm.selectedSchoolYears,
                                                  callback: function ($$v) {
                                                    _vm.selectedSchoolYears =
                                                      $$v
                                                  },
                                                  expression:
                                                    "selectedSchoolYears",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: { click: _vm.backStep4 },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("backLabel"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                dark: "",
                                                depressed: "",
                                                "x-large": "",
                                                elevation: "0",
                                              },
                                              on: { click: _vm.step4Validate },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "continueIntegrationLabel"
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-stepper-content",
                                { attrs: { step: "5" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mb-12",
                                      attrs: {
                                        height: _vm.stepperHeight,
                                        elevation: "0",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "8" } },
                                            [
                                              _c("v-select", {
                                                staticClass: "my-8",
                                                attrs: {
                                                  items: _vm.syncTimeItems,
                                                  "item-text": "timeToDisplay",
                                                  "item-value": "timeValue",
                                                  label:
                                                    _vm.$t("synchTimeLabel"),
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  required: "",
                                                  rules: _vm.localRules,
                                                },
                                                model: {
                                                  value: _vm.setUpSyncTime,
                                                  callback: function ($$v) {
                                                    _vm.setUpSyncTime = $$v
                                                  },
                                                  expression: "setUpSyncTime",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            align: "center",
                                            justify: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.setupStepCount = 4
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("backLabel"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "error",
                                                dark: "",
                                                depressed: "",
                                                "x-large": "",
                                                elevation: "0",
                                              },
                                              on: {
                                                click:
                                                  _vm.openConfirmationModal,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("finishLabel"))
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000, color: _vm.isDisconnected ? "" : "success" },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "pink", text: "" },
                        on: {
                          click: function ($event) {
                            _vm.snackBar = false
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" " + _vm._s(_vm.$t("closeLabel")) + " ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.snackBar,
            callback: function ($$v) {
              _vm.snackBar = $$v
            },
            expression: "snackBar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackBarText) + " ")]
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: _vm.agreeToAcceptChanges,
          },
          on: { apply: _vm.finishSetUporSaveTeacher },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _c("span", [_vm._v(_vm._s(_vm.mappingDistrictName))]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.confirmationModal,
            callback: function ($$v) {
              _vm.confirmationModal = $$v
            },
            expression: "confirmationModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _vm.totalTeachersAddedCounter > 0
                ? _c(
                    "div",
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("agreeToAddTeachersBodyLabel")) +
                            " "
                        ),
                      ]),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v(
                          _vm._s(_vm.$t("totalTeachersAdded")) +
                            " " +
                            _vm._s(_vm.totalTeachersAddedCounter)
                        ),
                      ]),
                      _vm.setupWizard
                        ? _c(
                            "v-card",
                            {
                              staticClass: "pa-3 ma-3",
                              staticStyle: {
                                "overflow-y": "auto",
                                "max-height": "300px",
                              },
                              attrs: { outlined: "" },
                            },
                            [
                              _vm.setupWizard
                                ? _c(
                                    "div",
                                    _vm._l(
                                      _vm.teachersMappingArray,
                                      function (item, i) {
                                        return _c("span", { key: i }, [
                                          item.setUpPBTeacherName === "add"
                                            ? _c("li", [
                                                _vm._v(
                                                  " " + _vm._s(item.email) + " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    _vm._l(
                                      _vm.connectedTeachersArray,
                                      function (item, i) {
                                        return _c("span", { key: i }, [
                                          item.manageMappingPBTeacherName ===
                                          "add"
                                            ? _c("li", [
                                                _vm._v(
                                                  " " + _vm._s(item.email) + " "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.totalSchoolYearsToBeAdded > 0
                ? _c(
                    "div",
                    [
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("agreeToAddSchoolYearsBodyLabel")) +
                            " "
                        ),
                      ]),
                      _c("span", { staticClass: "ml-3" }, [
                        _vm._v(
                          _vm._s(_vm.$t("totalSchoolYearsAddedLabel")) +
                            " " +
                            _vm._s(_vm.totalSchoolYearsToBeAdded)
                        ),
                      ]),
                      _c(
                        "v-card",
                        {
                          staticClass: "pa-3 ma-3",
                          staticStyle: {
                            "overflow-y": "auto",
                            "max-height": "300px",
                          },
                          attrs: { outlined: "" },
                        },
                        [
                          _vm.setupWizard
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.schoolYearsMappingArray,
                                  function (item, i) {
                                    return _c("span", { key: i }, [
                                      item.setUpPBSchoolYear === "add"
                                        ? _c("li", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.setUpSchoolYear) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _c(
                                "div",
                                _vm._l(
                                  _vm.manageMappingSchoolYearsArray,
                                  function (item, i) {
                                    return _c("span", { key: i }, [
                                      item.manageMappingPBSchoolYearName ===
                                      "add"
                                        ? _c("li", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  item.classlinkSchoolYearName
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  }
                                ),
                                0
                              ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-checkbox", {
                    staticClass: "ml-3",
                    attrs: { label: _vm.$t("agreeToAddTeachersLabel") },
                    model: {
                      value: _vm.agreeToAcceptChanges,
                      callback: function ($$v) {
                        _vm.agreeToAcceptChanges = $$v
                      },
                      expression: "agreeToAcceptChanges",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pb-base-modal",
        {
          attrs: {
            maxWidth: 500,
            expandable: false,
            withActions: false,
            withApply: false,
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("actionCantBeDoneLabel"))),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.alreadyExistModal,
            callback: function ($$v) {
              _vm.alreadyExistModal = $$v
            },
            expression: "alreadyExistModal",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.$t("duplicateSelectedPBData")) + " "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }